import React from 'react';
import Slider from "react-slick";

class OurServicesIntro extends React.Component {
  render() {
    const settings = {
      dots: false,
      autoplaySpeed: 5000,
      infinite: true,
      speed: 800,
      slidesToShow: 1,
      autoplay: true,
      slidesToScroll: 1,
      fade: true
    };
  

    return (
      <div id="service-bar" className="About-section">
          <div className="About-section-left">
              <div className="About-section-left-container">
                  <p className="About-section-left-container__title">About us</p>
                  <hr/>
                  <div className="About-section-left-container__text">
                      <p>Hirex Ltd is a family business which has been trading since the 1960’s. We offer our clients a full turnkey service for their exhibition or event needs.</p>
                      <p>Our services cover both exhibition/event organisers and exhibitor requirements.</p>
                      <p>Hirex’s in house graphics department can produce large or small graphics or signage for your exhibition or for other purposes.</p>
                  </div>
              </div>
          </div>
          <div className="About-section-right">
          <Slider {...settings}>
            <div className="about-slide-1" style={{ height: 100 }}>
            </div>
            <div className="about-slide-2">
            </div>
            <div className="about-slide-3">
            </div>
            <div className="about-slide-4">
            </div>
            <div className="about-slide-5">
            </div>
            <div className="about-slide-6">
            </div>
          </Slider>
          </div>
      
      </div>
    );
  }
}
export default OurServicesIntro;
