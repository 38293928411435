import React from 'react';
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";


const OurServicesIntro = (props) => {
  

  return (
    <div className="our-services-intro-section">
        <div className="our-services-intro-section-container">
            <p className="our-services-intro-section-container__title">Our Services</p>
            <hr/>
            <div className="our-services-intro-section-container__text">
                <p>Hirex have been instrumental in the creation of an award winning one stop shop and event service group namely ‘IG’. Our full range of services to both Event Organisers and Exhibitors are offered through ‘IG’ and can be explored further within the links below.</p>
            </div>
        </div>
        <div className="our-services-intro-section-links">
            <Link to="/services" className="our-services-intro-section-links__link our-services-intro-section-links__link--first" onClick={props.organiser}>
              <svg width="77px" height="82px" viewBox="0 0 77 82" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Artboard" transform="translate(-598.000000, -342.000000)" stroke="#1F1F1F" stroke-width="3">
                        <g id="Organiser-Services" transform="translate(600.000000, 344.000000)">
                            <g id="Organiser-Icon" transform="translate(0.000000, 0.000000)">
                                <polyline id="Stroke-1" points="37.63008 34.46016 37.63008 40.73776 60.26208 40.73776 60.26208 46.70576"></polyline>
                                <polyline id="Stroke-2" points="37.63008 40.73752 13.86608 40.73752 13.86608 46.70552"></polyline>
                                <path d="M46.6824,9.05272 C46.6824,14.05272 42.6296,18.10552 37.6304,18.10552 C32.6304,18.10552 28.5768,14.05272 28.5768,9.05272 C28.5768,4.05272 32.6304,-8e-05 37.6304,-8e-05 C42.6296,-8e-05 46.6824,4.05272 46.6824,9.05272 Z" id="Stroke-3"></path>
                                <path d="M24.02928,32.28752 C24.02928,27.69712 26.35568,23.64912 29.89328,21.25872" id="Stroke-5"></path>
                                <path d="M44.7668,21.25856 C48.3044,23.64896 50.6308,27.69696 50.6308,32.28736" id="Stroke-7"></path>
                                <path d="M22.91912,56.58048 C22.91912,61.58048 18.86632,65.63328 13.86632,65.63328 C8.86632,65.63328 4.81272,61.58048 4.81272,56.58048 C4.81272,51.58048 8.86632,47.52768 13.86632,47.52768 C18.86632,47.52768 22.91912,51.58048 22.91912,56.58048 Z" id="Stroke-9"></path>
                                <path d="M0.3996,80 C0.3996,75.4088 2.726,71.3608 6.2636,68.9712" id="Stroke-11"></path>
                                <path d="M21.13712,68.97112 C24.67552,71.36072 27.00112,75.40872 27.00112,79.99992" id="Stroke-13"></path>
                                <path d="M69.31448,56.58048 C69.31448,61.58048 65.26168,65.63328 60.26248,65.63328 C55.26248,65.63328 51.20888,61.58048 51.20888,56.58048 C51.20888,51.58048 55.26248,47.52768 60.26248,47.52768 C65.26168,47.52768 69.31448,51.58048 69.31448,56.58048 Z" id="Stroke-15"></path>
                                <path d="M46.65192,80 C46.65192,75.4088 48.97752,71.3608 52.51592,68.9712" id="Stroke-17"></path>
                                <path d="M67.38864,68.97112 C70.92704,71.36072 73.25264,75.40872 73.25264,79.99992" id="Stroke-19"></path>
                            </g>
                        </g>
                    </g>
                </g>
              </svg>
              <p className="our-services-intro-section-links__link-text">Organiser Services</p>
              <div className="our-services-intro-section-links__link-text-arrow">
                <p>
                  Learn More
                  <svg className="our-services-intro-section-links__link-arrow" width="24px" height="14px" viewBox="0 0 24 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Artboard" transform="translate(-791.000000, -517.000000)" stroke="#1F1F1F" stroke-width="1.5">
                              <g id="Arrow" transform="translate(802.500000, 524.000000) rotate(-180.000000) translate(-802.500000, -524.000000) translate(792.000000, 518.000000)">
                                  <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                  <path d="M1.12772042,6 L20.4628209,6" id="Line" stroke-linecap="square"></path>
                              </g>
                          </g>
                      </g>
                  </svg>
                </p>
              </div>
            </Link>
            <div class="divider"></div>
            <Link to="/services" className='our-services-intro-section-links__link our-services-intro-section-links__link--second' onClick={props.exhibitor}>
            <svg width="81px" height="84px" viewBox="0 0 81 84" version="1.1">
              <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="Artboard" transform="translate(-232.000000, -361.000000)" stroke="#1F1F1F" stroke-width="3">
                      <g id="Exhibition-Services" transform="translate(234.000000, 363.000000)">
                          <g id="Exhibitor-Icon">
                              <polyline id="Stroke-1" points="26.6889505 22.0003168 49.8192475 22.0003168 46.204198 31.6066535 25.6505347 31.6058614 25.6505347 79.6407129 7.63786139 79.6407129 7.63786139 35.2090297"></polyline>
                              <path d="M16.6278812,47.3356832 L16.6278812,79.6406337" id="Stroke-3"></path>
                              <polyline id="Stroke-5" points="30.9192871 8.7910495 77.2868119 8.7910495 77.2868119 48.4187723 30.3759208 48.4187723"></polyline>
                              <path d="M70.2502178,48.4186139 L70.2502178,79.6407921" id="Stroke-7"></path>
                              <path d="M26.1227723,9.67318812 C26.1227723,14.7765545 21.9857426,18.9135842 16.8823762,18.9135842 C11.779802,18.9135842 7.6419802,14.7765545 7.6419802,9.67318812 C7.6419802,4.56982178 11.779802,0.432792079 16.8823762,0.432792079 C21.9857426,0.432792079 26.1227723,4.56982178 26.1227723,9.67318812 Z" id="Stroke-9"></path>
                              <path d="M7.30859406,54.8039604 L0.432554455,54.8039604 L0.432554455,34.8427723 C0.432554455,30.0784158 3.02819802,25.9192079 6.88166337,23.7021782" id="Stroke-11"></path>
                          </g>
                      </g>
                  </g>
              </g>
            </svg>
              <p className="our-services-intro-section-links__link-text">Exhibitor Services</p>
              <div className="our-services-intro-section-links__link-text-arrow">
                <p>
                  Learn More 
                  <svg className="our-services-intro-section-links__link-arrow" width="24px" height="14px" viewBox="0 0 24 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Artboard" transform="translate(-791.000000, -517.000000)" stroke="#1F1F1F" stroke-width="1.5">
                              <g id="Arrow" transform="translate(802.500000, 524.000000) rotate(-180.000000) translate(-802.500000, -524.000000) translate(792.000000, 518.000000)">
                                  <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                  <path d="M1.12772042,6 L20.4628209,6" id="Line" stroke-linecap="square"></path>
                              </g>
                          </g>
                      </g>
                  </svg>
                </p>
              </div>
            </Link>
        </div>
    </div>
  );
}

export default OurServicesIntro;
