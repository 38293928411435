import React, { Component } from 'react';
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';


   
class ExhibitorSection extends Component {


     state={
        slider: [
            '<div class="ex-default"></div>',
            '<div class="shell-serv-first"></div><div class="shell-serv-second"></div><div class="shell-serv-third"></div>',
            '<div class="design-first"></div><div class="design-second"></div><div class="design-third"></div>',
            '<div class="graphics-serv-first"></div><div class="graphics-serv-second"></div><div class="graphics-serv-third"></div>',
            '<div class="diy-first"></div><div class="diy-second"></div><div class="diy-third"></div>',
        ],
        img: ["ex-default", "ex-shell-schemes", 'design-and-build', 'graphic-services', 'diy-services' ],
        copy: [
            {
                title: "Choose a service",
                text: "If you are an exhibitor needing assistance at an event, we offer the following services to assist you. Click on the different services to learn more.",
                link: ""
            },
            {   title: "Shell Schemes Extras",
                text: "If you are exhibiting at one of our events then we offer shell scheme extras to assist you exhibit.",
                link: "http://ig.events/exhibitor/shell-scheme-fittings-fixtures"  
            }, {
                title: "Design/Custom Build",
                text: "We offer a full design and build service for space only exhibitors.",
                link: "http://ig.events/exhibitor/custom-build"
            }, {
                title: "Graphical/Signage Services",
                text: "Our in-house graphic department can provide quality graphics to suit all your requirements.",
                link: "http://ig.events/exhibitor/graphic-services-exhibitor"
            }, {
                title: "DIY Services",
                text: "When planning your Conference or Exhibition ﬁrst impressions count. We specialise in making your registration area a focal point of your event.",
                link: "http://ig.events/exhibitor/portable-systems"
            }
            ],
        counter: 0
    }

    changeCounter = (e,number) => {
        let innerItems = document.querySelectorAll('.innerItems')
        innerItems.forEach(item => { if ( item.style ) {
            item.style.maxHeight = '0px' }}
            ) 
        
        let arrowSvg = document.querySelectorAll ('.organiser-section-right-link svg')
        arrowSvg.forEach(item => { if (item.style) {
            item.style.transform = "rotate(0deg)" } 
         })
        console.log(arrowSvg)

        this.setState({ counter: number })

        if (e.target.parentElement.parentElement.querySelector('.innerItems')) {
            e.target.parentElement.parentElement.querySelector('.innerItems').style.maxHeight = '401px'
        }
        if (e.target.parentElement.parentElement.querySelector("svg")) {
            e.target.parentElement.parentElement.querySelector("svg").style.transform = "rotate(90deg)"
        }
    }
 
    render() {
        var settings = {
            autoplay: true,
            dots: false,
            autoplaySpeed: 5000,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true 
          };
      return (
        <div className="exhibitor-section">
            <div className="exhibitor-section-left">
                <div className="exhibitor-section-left-image-container">
                    <Slider {...settings}>
                    { ReactHtmlParser(this.state.slider[this.state.counter]) }
                    </Slider>
                </div>
                <div className="exhibitor-section-left-container">
                    <p className="our-services-head-left-container__title">{this.state.copy[this.state.counter].title}</p>
                    <hr/>
                    <p className="our-services-head-left-container__text">{this.state.copy[this.state.counter].text}
                    { this.state.copy[this.state.counter].link ?
                        <a href={this.state.copy[this.state.counter].link}>Learn more 
                        
                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        </a>
                        :
                    <div></div> }
                    
                    </p>
                </div>
                <div className="exhibitor-section-left-container-large">
                    <p className="our-services-head-left-container-large__title">{this.state.copy[0].title}</p>
                    <hr/>
                    <p className="our-services-head-left-container-large__text">{this.state.copy[0].text}
                   
                    </p>
                </div>
            </div>
            
            <div className="exhibitor-section-right">
                <div className="exhibitor-section-right-container">
                    <ul>
                        <li id="shell-schemes" onClick={(e) =>  {this.changeCounter(e,1)} }>
                            <div className="organiser-section-right-link">
                                <div className="exhibitor-section-right-link-inner">Shell Schemes Extras</div>
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Artboard" transform="translate(-849.000000, -257.000000)" stroke="#1F1F1F">
                                            <polyline id="Path-11" transform="translate(853.500000, 264.000000) scale(-1, 1) rotate(-360.000000) translate(-853.500000, -264.000000) " points="857 257 850 264 857 271"></polyline>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="innerItems">
                                 <div className="organiser-section-left-image-container-small">
                                 <Slider {...settings}>
                                    { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[1].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[1].text}
                                    { this.state.copy[1].link ?
                                        <a href={this.state.copy[1].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    
                                    
                                    </p>
                                </div> 
                            </div>
                        </li>
                        <li id="design-and-build" onClick={(e) =>  {this.changeCounter(e, 2)} }>
                            <div className="organiser-section-right-link">
                                <div className="exhibitor-section-right-link-inner">Design/Custom Build </div>                         
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Artboard" transform="translate(-849.000000, -257.000000)" stroke="#1F1F1F">
                                            <polyline id="Path-11" transform="translate(853.500000, 264.000000) scale(-1, 1) rotate(-360.000000) translate(-853.500000, -264.000000) " points="857 257 850 264 857 271"></polyline>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="innerItems">
                                 <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[2].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[2].text}</p>
                                </div> 
                            </div> 
                        </li>
                        <li id="graphic-services" onClick={(e) =>  {this.changeCounter(e, 3)} }> 
                            <div className="organiser-section-right-link">
                                <div className="exhibitor-section-right-link-inner">Graphical/Signage Services</div>                        
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Artboard" transform="translate(-849.000000, -257.000000)" stroke="#1F1F1F">
                                            <polyline id="Path-11" transform="translate(853.500000, 264.000000) scale(-1, 1) rotate(-360.000000) translate(-853.500000, -264.000000) " points="857 257 850 264 857 271"></polyline>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="innerItems">
                                 <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[3].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[3].text}
                                    { this.state.copy[1].link ?
                                        <a href={this.state.copy[1].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>        
                        </li>
                        <li id="diy-services" onClick={(e) =>  {this.changeCounter(e,4)} }>
                            <div className="organiser-section-right-link">
                                <div className="exhibitor-section-right-link-inner">DIY Services</div>                            
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Artboard" transform="translate(-849.000000, -257.000000)" stroke="#1F1F1F">
                                            <polyline id="Path-11" transform="translate(853.500000, 264.000000) scale(-1, 1) rotate(-360.000000) translate(-853.500000, -264.000000) " points="857 257 850 264 857 271"></polyline>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="innerItems">
                                 <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[4].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[4].text}
                                    { this.state.copy[1].link ?
                                        <a href={this.state.copy[1].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>        
                        </li>
                    </ul>
                </div>
            </div>
         </div>
      );
    }
  }


export default ExhibitorSection;
