import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from './Header';
import ServicesBar from './ServicesBar';
import About from './About'
import OurServicesIntro from './OurServicesIntro';
import BackgroundSlider from 'react-background-slider';
import Slide1 from './images/slide_1.jpg'
import Slide2 from './images/slide_2.jpg'
import Slide3 from './images/slide_3.jpg'

class HirexHome extends Component {
    render() {
      return (
        <div>
          <Helmet>
            <title>Hirex | Exhibition Design & Construction Services</title>
            <meta name="description" content="We’re one of the industry’s leading specialist providers in the design, build and installation of event and exhibition services." />
            <link rel="canonical" href="https://www.hirex.co.uk/" />
            <meta name="google-site-verification" content="u_edawhH7gQFU986Uck9wetIYJq9RBWtCS04k3E9fzY" />
          </Helmet>
          <BackgroundSlider images={[Slide1, Slide2, Slide3]} duration={10} transition={2} />
          <Header />
          <ServicesBar />
          <About />
          <OurServicesIntro organiser={this.props.organiser} exhibitor={this.props.exhibitor} />
        </div>
      );
    }
  }


export default HirexHome;
