import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import HirexServicesHead from './HirexServicesHead'
import ServicesBar from './ServicesBar';
import OrganiserSection from './OrganiserSection'
import ExhibitorSection from './ExhibitorSection'
import {Animated} from "react-animated-css";

class HirexServices extends Component {
    render() {
      return (
        <div>
          <Helmet>
            <title>Exhibition Stand Design | Shell Schemes | Conference Stands | Custom Exhibition Stands | Hirex</title>
            <meta name="description" content="Whether you are an organiser or an exhibitor needing assistance, Hirex has a wide range of services & experience to support you." />
            <link rel="canonical" href="https://www.hirex.co.uk/services" />
          </Helmet>
          <ServicesBar />
          <HirexServicesHead toExhibitor={this.props.toExhibitor} toOrganiser={this.props.toOrganiser} columnLoad={this.props.columnLoad} />
          { this.props.columnLoad.organiser ?
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
            <OrganiserSection />
          </Animated>
          :
          <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={true}>
            <ExhibitorSection />
           </Animated> }
        </div>
      );
    }
  }


export default HirexServices;
