import React, { Component } from 'react';
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';


class OrganiserSection extends Component {
   
     state={
        slider: [
            '<div class="default"></div>',
            '<div class="shell-org-first"></div><div class="shell-org-second"></div><div class="shell-org-third"></div>',
            '<div class="conf-stands-first"></div><div class="conf-stands-second"></div><div class="conf-stands-third"></div>',
            '<div class="feature-first"></div><div class="feature-second"></div><div class="feature-third"></div>',
            '<div class="registration-first"></div><div class="registration-second"></div><div class="registration-third"></div>',
            '<div class="graphics-first"></div><div class="graphics-second"></div><div class="graphics-third"></div>',
            '<div class="posterboards-first"></div><div class="posterboards-second"></div><div class="posterboards-third"></div>',
            '<div class="floorplans-first"></div><div class="floorplans-second"></div><div class="floorplans-third"></div>',
            '<div class="custom-first"></div><div class="custom-second"></div><div class="custom-third"></div>',
        ],
        img: ["default", "shell-schemes", 'conference-stands', 'feature-areas', 'registration-areas', 'graphic-services', 'posterboards', 'floorplans', 'custom-builds' ],
        copy: [
            {
                title: "Choose a service",
                text: "If you are an organiser needing assistance, we offer the following services to assist you. Click on the different services to learn more."
            },
            {   title: "Shell Schemes",
                text: "Providing a high quality Shell system and service support company is vital in building good relationships with your exhibitors.",
                link: "http://ig.events/event-organiser/shell-schemes"   
            }, {
                title: "Conference Stands",
                text: "Supplying either unique full turnkey conference stands with in-built AV or basic conference stands with ad on options, to be used as fillers for break out areas in conference venues or in exhibition halls won’t be an issue.",
                link: "http://ig.events/event-organiser/conference-stands"
            }, {
                title: "Feature Areas",
                text: "When designing your event it is important to highlight special areas within the show, such as seminars, workshops, catering or bars.",
                link: "http://ig.events/event-organiser/show-feature-areas"
            }, {
                title: "Registration Areas",
                text: "When planning your Conference or Exhibition ﬁrst impressions count. We specialise in making your registration area a focal point of your event.",
                link: "http://ig.events/event-organiser/event-registration-services"
            }, {
                title: "Graphic Services",
                text: "We produce quality graphics, large or small to suit your requirements.",
                link: "http://ig.events/event-organiser/graphic-services-organiser"
            }, {
                title: "Posterboards",
                text: "Supply only or supply, installation and removal of landscape or portrait poster boards.",
                link: "http://ig.events/event-organiser/poster-panel-panel-hire"
            }, {
                title: "Floor Plans / CAD Services",
                text: "Having accurate and up to date floor plans is an essential part of your show, for both potential exhibitors and your planning.",
                link: "http://ig.events/event-organiser/floorplan-services"
            }, {
                title: "Custom Build",
                text: "We offer a full range of custom builds for from small to large budgets.",
                link: "http://ig.events/event-organiser/custom-build"
            }
            ],
        counter: 0
    }

    changeCounter = (e, number) => {
        let innerItems = document.querySelectorAll('.innerItems')
        innerItems.forEach(item => { if ( item.style ) {
            item.style.maxHeight = '0px' }}
            ) 
        
        let arrowSvg = document.querySelectorAll ('.organiser-section-right-link svg')
        arrowSvg.forEach(item => { if (item.style) {
            item.style.transform = "rotate(0deg)" } 
         })
        console.log(arrowSvg)

        this.setState({ counter: number })

        if (e.target.parentElement.parentElement.querySelector('.innerItems')) {
            e.target.parentElement.parentElement.querySelector('.innerItems').style.maxHeight = '459px'
        }
        if (e.target.parentElement.parentElement.querySelector("svg")) {
            e.target.parentElement.parentElement.querySelector("svg").style.transform = "rotate(-90deg)"
        }
    }

    render() {
        var settings = {
            autoplay: true,
            dots: false,
            autoplaySpeed: 5000,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true
          };
      return (
        <div className="organiser-section">
            <div className="organiser-section-left">
                <div className="organiser-section-left-image-container">
                    {/* <div className={this.state.img[this.state.counter] }> */}
                    <Slider {...settings}>
                    { ReactHtmlParser(this.state.slider[this.state.counter]) }
                       
                    </Slider>
                    {/* </div>  */}
                </div>
                <div className="organiser-section-left-container">
                    <p className="our-services-head-left-container__title">{this.state.copy[this.state.counter].title}</p>
                    <hr/>
                    <p className="our-services-head-left-container__text">{this.state.copy[this.state.counter].text}
                    { this.state.copy[this.state.counter].link ?
                        <a href={this.state.copy[this.state.counter].link}>Learn more 
                        
                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        </a>
                        :
                    <div></div> }
                    </p>
                </div>
                <div className="organiser-section-left-container-large">
                    <p className="our-services-head-left-container-large__title">{this.state.copy[0].title}</p>
                    <hr/>
                    <p className="our-services-head-left-container-large__text">{this.state.copy[0].text}</p>
                    
                </div>
            </div>
            
            <div className="organiser-section-right">
                <div className="organiser-section-right-container">
                    <ul>
                        <li onClick={(e) =>  {this.changeCounter(e, 1); return false; } }  id="shell-schemes" >
                            <div className="organiser-section-right-link">
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="organiser-section-right-link-inner">Shell Schemes</div>
                            </div> 
                            <div className="innerItems">
                                 <div className="organiser-section-left-image-container-small">
                                 <Slider {...settings}>
                                    { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[1].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[1].text}
                                    { this.state.copy[1].link ?
                                        <a href={this.state.copy[1].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>
                        </li>


                        <li onClick={(e) =>  {this.changeCounter(e,2)} }  id="conference-stands">
                        <div className="organiser-section-right-link">
                            <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                            </svg>
                            <div className="organiser-section-right-link-inner">Conference Stands</div>
                        </div>
                        <div className="innerItems">
                                <div className="organiser-section-left-image-container-small">
                                <Slider {...settings}>
                                    { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                 </Slider>
                            </div>
                            <div className="organiser-section-left-container-small">
                                {/* <p className="our-services-head-left-container__title-small">{this.state.copy[2].title}</p>
                                <hr/> */}
                                <p className="our-services-head-left-container__text-small">{this.state.copy[2].text}
                                { this.state.copy[2].link ?
                                        <a href={this.state.copy[2].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                </p>
                            </div> 
                        </div>
                        </li>
                        <li onClick={(e) =>  {this.changeCounter(e,3)} } id="feature-areas" >
                            <div className="organiser-section-right-link">
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="organiser-section-right-link-inner">Feature Areas</div>
                            </div>
                            <div className="innerItems">
                                <div className="organiser-section-left-image-container-small">
                                <Slider {...settings}>
                                    { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[3].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[3].text}
                                    { this.state.copy[3].link ?
                                        <a href={this.state.copy[3].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>
                        </li>
                        <li onClick={(e) =>  {this.changeCounter(e,4)} } id="registration-areas" >
                            <div className="organiser-section-right-link">
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="organiser-section-right-link-inner">Registration Areas</div>
                            </div>
                            <div className="innerItems">
                                <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[4].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[4].text}
                                    { this.state.copy[4].link ?
                                        <a href={this.state.copy[4].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>
                        </li>
                        <li onClick={(e) =>  {this.changeCounter(e,5)} } id="graphic-services" >
                            <div className="organiser-section-right-link">
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="organiser-section-right-link-inner">Graphic Services</div>
                            </div>
                            <div className="innerItems">
                                <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[5].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[5].text}
                                    { this.state.copy[5].link ?
                                        <a href={this.state.copy[5].link}>Learn more
                                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>
                        </li>

                        <li onClick={(e) =>  {this.changeCounter(e,6)} } id="postboards" >
                            <div className="organiser-section-right-link">
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="organiser-section-right-link-inner">Posterboards</div>
                            </div>
                            <div className="innerItems">
                                <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[6].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[6].text}
                                    { this.state.copy[6].link ?
                                        <a href={this.state.copy[6].link}>Learn more
                                            <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>
                        </li>
                        <li onClick={(e) =>  {this.changeCounter(e, 7)} } id="floorplans" >
                            <div className="organiser-section-right-link">
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="organiser-section-right-link-inner">Floor Plans / CAD Services</div>
                            </div>
                            <div className="innerItems">
                                <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[7].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[7].text}
                                    { this.state.copy[7].link ?
                                        <a href={this.state.copy[7].link}>Learn more 
                                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>
                        </li>
                        <li onClick={(e) =>  {this.changeCounter(e, 8)} } id="custom-build" >
                            <div className="organiser-section-right-link">
                                <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Services-Pages---V1---Opt.3-(Organiser)" transform="translate(-989.000000, -1239.000000)" stroke="#1F1F1F">
                                            <g id="Service-Side-Block" transform="translate(840.000000, 1134.000000)">
                                                <g id="SERVICE-LIST" transform="translate(100.000000, 81.000000)">
                                                    <polyline id="Path-11" transform="translate(53.500000, 31.000000) scale(-1, 1) rotate(180.000000) translate(-53.500000, -31.000000) " points="57 24 50 31 57 38"></polyline>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <div className="organiser-section-right-link-inner">Custom Build</div>
                            </div>
                            <div className="innerItems">
                                <div className="organiser-section-left-image-container-small">
                                    <Slider {...settings}>
                                        { ReactHtmlParser(this.state.slider[this.state.counter]) }
                                    </Slider>
                                </div>
                                <div className="organiser-section-left-container-small">
                                    {/* <p className="our-services-head-left-container__title-small">{this.state.copy[8].title}</p>
                                    <hr/> */}
                                    <p className="our-services-head-left-container__text-small">{this.state.copy[8].text}
                                    { this.state.copy[8].link ?
                                        <a href={this.state.copy[8].link}>Learn more
                                        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                <g id="Services-Pages---V1---Opt.3-(Organiser-/-Service-Active)" transform="translate(-306.000000, -1685.000000)" stroke="#FFFFFF" stroke-width="1.5">
                                                    <g id="Group-4" transform="translate(100.000000, 1390.000000)">
                                                        <g id="Group-3" transform="translate(99.500000, 289.500000)">
                                                            <g id="Arrow" transform="translate(113.399495, 12.399495) rotate(-4545.000000) translate(-113.399495, -12.399495) translate(105.399495, 6.399495)">
                                                                <polyline id="Path-11" points="6.85714286 0 0 6 6.85714286 12"></polyline>
                                                                <path d="M1.12772042,6 L15.7218936,6" id="Line" stroke-linecap="square"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        </a>
                                        :
                                    <div></div> }
                                    </p>
                                </div> 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
         </div>
      );
    }
  }


export default OrganiserSection;
