import React, { Component } from 'react';





class HirexServicesHead extends Component {


  

    render() {
      let columnleft = "our-services-head-left"
      let columnRight = "our-services-head-right"
      let columnContainer= "our-services-head-left-container"
      let organiserButton = "our-services-buttons-left"
      let exhibitorButton = "our-services-buttons-right"

      if (this.props.columnLoad.organiser) {
        columnleft += " active"
        columnRight = "our-services-head-right"
        columnContainer= "our-services-head-left-container"
        organiserButton += " active"
        exhibitorButton = "our-services-buttons-right"
       
    } else {
        columnRight += " active"
        columnleft = "our-services-head-left"
        columnContainer += " org"
        organiserButton = "our-services-buttons-left"
        exhibitorButton += " active"
    }

      return (
        <div>
          <div className="our-services-head">
            <div className={columnleft}  onClick={this.props.toOrganiser}>
              <div className={columnContainer}>
                <div className="serv-contain">
                  <p className="our-services-head-left-container__title">Our Services</p>
                  <hr/>
                  <p className="our-services-head-left-container__text">Whether you are organising a show for any number of exhibitors or you need assistance in your own individual exhibiting requirements or if you just need graphics for your own non-event use, Hirex has the experience and can support you.<br/> <strong>To learn more choose from Organiser or Exhibitor below.</strong></p>
                </div>
              </div>
            </div>
            <div className={columnRight} onClick={this.props.toExhibitor}>
            </div>
          </div>
          <div className="our-services-buttons">
              <div className={organiserButton} onClick={this.props.toOrganiser}>
                <p className="our-services-head-left-organiser">Organiser</p>
              </div>
              <div className={exhibitorButton}  onClick={this.props.toExhibitor}>
                <p className="our-services-head-right-exhibitor">Exhibitor</p>
              </div>
            </div>
        </div>
      );
    }
  }


export default HirexServicesHead;
