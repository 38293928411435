
import './App.css';
import './Services.css';
import './Sliders.css'

import Footer from './Footer';
import React, {Component} from 'react';
import { Route, withRouter, Link } from 'react-router-dom'
import HirexHome from './HirexHome';
import HirexServices from './HirexServices';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { loadReCaptcha } from 'react-recaptcha-google'



class App extends Component {
  state= {
    home: true,
    organiser: true     
  }

  componentDidMount() {
    loadReCaptcha();
  }

  servicesOrganiser = () => {
    this.setState({ home: false, organiser: true })
  }

  servicesExhibitor = () => {
    this.setState({ home: false, organiser: false })
  }

  openNav = () => {
    document.getElementById("mySidenav").style.width = "325px";
  }
  
  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  }

    render() {
      const mapStyles = {
        position: 'relative',
        width: '300px',
        height: '300px',
        left: '50',
        right: '50'
      };
      return (
        <div>
          <span  className="hamburger" onClick={ () => { this.openNav() }}>
            <svg width="25px" height="22px" viewBox="0 0 25 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g id="Mobile" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="Services-Page-(Organiser---Service-Active)---v1" transform="translate(-320.000000, -61.000000)" stroke="#FFFDFD" stroke-width="2.5">
                        <g id="Group" transform="translate(30.000000, 50.000000)">
                            <g id="Hamburger-Menu" transform="translate(290.000000, 12.000000)">
                                <path d="M0,0.5 L25,0.5" id="Line-2"></path>
                                <path d="M0,9.5 L25,9.5" id="Line-2-Copy"></path>
                                <path d="M5.62792969,18.8282869 L24.6054688,18.8282869" id="Line-2-Copy-2"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
           </span>

          <div id="mySidenav" className="sidenav">
            <a href="javascript:void(0)" className="closebtn" onClick={() => { this.closeNav()}}>&times;</a>
            <Link onClick={() => { this.closeNav()}} to="/">Home 
              <svg width="24px" height="15px" viewBox="0 0 24 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Landing-Header---V1-(Img.4---Full-Page)-Hover" transform="translate(-372.000000, -685.000000)" stroke="#FFFFFF" stroke-width="4">
                            <g id="Content-Card" transform="translate(100.000000, 300.000000)">
                                <g id="Learn-More-CTA" transform="translate(99.000000, 379.000000)">
                                    <g id="Arrow" transform="translate(185.000000, 12.500000) rotate(-90.000000) translate(-185.000000, -12.500000) translate(179.500000, 2.500000)">
                                        <polyline id="Path-11" points="10.0168269 0 0.0168269231 10 10.0168269 20"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </Link>
            <Link onClick={() => { this.closeNav()}} to="/Services">Services
              <svg width="24px" height="15px" viewBox="0 0 24 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Landing-Header---V1-(Img.4---Full-Page)-Hover" transform="translate(-372.000000, -685.000000)" stroke="#FFFFFF" stroke-width="4">
                            <g id="Content-Card" transform="translate(100.000000, 300.000000)">
                                <g id="Learn-More-CTA" transform="translate(99.000000, 379.000000)">
                                    <g id="Arrow" transform="translate(185.000000, 12.500000) rotate(-90.000000) translate(-185.000000, -12.500000) translate(179.500000, 2.500000)">
                                        <polyline id="Path-11" points="10.0168269 0 0.0168269231 10 10.0168269 20"></polyline>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </Link>
            <div className="sidenav-visit">
              <div className="sidenav-visit-address">
                <div className="sidenav-visit-address-title">
                  <p>Visit us</p>
                </div>
                <p>HIREX<br/>
                  Lomax Street,<br/>
                  Radcliffe,<br/>
                  Manchester<br/>
                  M16 1PX</p>
              </div>
                <Map
                  google={this.props.google}
                  containerStyle={{position: 'relative',
                                  width: '300px'}}
                  zoom={17}
                  style={mapStyles}
                  initialCenter={{ lat: 53.556175, lng: -2.328252}}
                  >
                  <Marker position={{ lat: 53.556175, lng: -2.328252}} />
                </Map>
            </div>
            <div className="sidenav-visit-privacy">
              <p>&copy; Hirex Ltd 2019 | Terms &amp; Privacy</p>
            </div>
          </div>

          <Route exact path='/' component={ () => 
            <HirexHome organiser={this.servicesOrganiser} exhibitor={this.servicesExhibitor} /> } />
           
  
          
          <Route path='/services' component={ () =>  
            <HirexServices toOrganiser={this.servicesOrganiser} toExhibitor={this.servicesExhibitor} columnLoad={this.state} /> } /> 
          <Footer />
        </div>
      );
    }
  }


export default withRouter(GoogleApiWrapper({
   apiKey: 'AIzaSyCEoaUgYItWypoAhRDbP8tAu0Iz8cswj28'
}) (App));

